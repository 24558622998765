export default {
  /**
   * cookie操作
   */
  setCookie(name, value, options) {
    options = options || {};
    if (value === null) {
      value = "";
      options.expires = -1;
    }
    var expires = "";
    if (
      options.expires &&
      (typeof options.expires == "number" || options.expires.toUTCString)
    ) {
      var date;
      if (typeof options.expires == "number") {
        date = new Date();
        date.setTime(date.getTime() + options.expires * 24 * 60 * 60 * 1000);
      } else {
        date = options.expires;
      }
      expires = "; expires=" + date.toUTCString(); // use expires attribute, max-age is not supported by IE
    }
    var path = options.path ? "; path=" + options.path : "";
    var domain = options.domain ? "; domain=" + options.domain : "";
    var s = [cookie, expires, path, domain, secure].join("");
    var secure = options.secure ? "; secure" : "";
    var c = [name, "=", encodeURIComponent(value)].join("");
    var cookie = [c, expires, path, domain, secure].join("");
    document.cookie = cookie;
  },
  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },
  formatStrByDot(str, start = 7, end = 5) {
    var reg = new RegExp("(.{" + start + "})(.+)(.{" + end + "})", "g");
    if (String(str).length > 12) {
      return String(str).replace(reg, "$1...$3");
    }
    return str;
  }
};
