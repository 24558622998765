import axios from "axios";

axios.defaults.baseURL = "/main/";

// 路由请求拦截
// http request 拦截器
// axios.interceptors.request.use((config) => {
//   return config;
// });

// http response 拦截器
axios.interceptors.response.use(
  (res) => {
    if (res.config.method == "options") {
      return false;
    }
    return res && res.data;
  },
  (error) => {
    return Promise.reject(error); // 返回接口返回的错误信息
  }
);

export default axios;
