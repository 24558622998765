import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/assets/js/axios";
import i18n from "@/assets/js/i18n";
import Loading from "@/components/Loading";
import Toast from "@/components/Toast";
import VueLazyload from "vue-lazyload";
import "@/assets/css/fonts.css";
import "@/assets/css/index.css";
Vue.use(Loading);
Vue.use(Toast);
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require("@/assets/img/icon/default.svg"),
  loading: require("@/assets/img/icon/default.svg"),
  attempt: 1,
});
Vue.prototype.axios = axios;
Vue.config.productionTip = false;

// 混入方法
import { mapGetters } from "vuex";
Vue.mixin({
  computed: {
    ...mapGetters(["worldName", "worldLogo"]),
    worldPath() {
      return this.$route?.params?.subdomain;
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
