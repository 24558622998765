import Vue from "vue";
import VueRouter from "vue-router";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/:subdomain",
    name: "Home",
    component: () => import("../views/Home.vue"),
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
