<template>
  <footer class="flex-between-center">
    <p class="text-grey">Powered by Collectify.app</p>
    <div>
      <a
        v-if="twitter"
        :href="`https://twitter.com/${twitter}`"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="svg-icon"
        >
          <path
            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
            fill="#4C6FFF"
            fill-opacity="0.15"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.966 11.9046C23.4079 12.1521 22.8083 12.3193 22.1789 12.3945C22.8213 12.0094 23.3146 11.3997 23.547 10.6731C22.9458 11.0296 22.2799 11.2886 21.5711 11.4282C21.0035 10.8234 20.1949 10.4456 19.3 10.4456C17.5815 10.4456 16.1883 11.8387 16.1883 13.557C16.1883 13.8009 16.2159 14.0384 16.2689 14.2661C13.6829 14.1363 11.3902 12.8976 9.85559 11.0151C9.58776 11.4747 9.43432 12.0092 9.43432 12.5794C9.43432 13.6589 9.98368 14.6112 10.8186 15.1692C10.3085 15.1531 9.82874 15.0131 9.40919 14.7801C9.40897 14.793 9.40897 14.8061 9.40897 14.8192C9.40897 16.3267 10.4815 17.5843 11.9049 17.8701C11.6438 17.9413 11.3689 17.9793 11.0851 17.9793C10.8846 17.9793 10.6897 17.9598 10.4998 17.9235C10.8957 19.1597 12.0448 20.0592 13.4063 20.0844C12.3415 20.9189 10.9998 21.4163 9.54205 21.4163C9.29092 21.4163 9.04323 21.4016 8.7998 21.3729C10.1768 22.2557 11.8123 22.7708 13.5695 22.7708C19.2927 22.7708 22.4223 18.0295 22.4223 13.9178C22.4223 13.7829 22.4193 13.6487 22.4133 13.5153C23.0212 13.0766 23.5488 12.5286 23.966 11.9046Z"
            fill="#4C6FFF"
          />
        </svg>
      </a>
      <a
        v-if="discord"
        :href="discord"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="svg-icon"
        >
          <path
            d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
            fill="#4C6FFF"
            fill-opacity="0.15"
          />
          <path
            d="M22.2601 10.1461C21.0866 9.60946 19.8404 9.21831 18.5396 9C18.3759 9.28199 18.194 9.66403 18.0666 9.96422C16.6831 9.76409 15.3095 9.76409 13.945 9.96422C13.8177 9.66403 13.6267 9.28199 13.472 9C12.1622 9.21831 10.9159 9.60946 9.7507 10.1461C7.39474 13.621 6.758 17.0139 7.07637 20.3613C8.64094 21.4984 10.151 22.1898 11.6346 22.6446C11.9984 22.1534 12.3259 21.6257 12.6079 21.0709C12.0712 20.8708 11.5618 20.6252 11.0706 20.3341C11.198 20.2431 11.3253 20.1431 11.4436 20.043C14.409 21.3984 17.6209 21.3984 20.5499 20.043C20.6773 20.1431 20.7955 20.2431 20.9229 20.3341C20.4317 20.6252 19.9223 20.8708 19.3856 21.0709C19.6676 21.6257 19.9951 22.1534 20.3589 22.6446C21.8416 22.1898 23.3607 21.4984 24.9171 20.3613C25.3082 16.4863 24.2976 13.1207 22.2601 10.1461ZM13.0172 18.2965C12.1258 18.2965 11.3981 17.4869 11.3981 16.4954C11.3981 15.5039 12.1076 14.6943 13.0172 14.6943C13.9178 14.6943 14.6545 15.5039 14.6363 16.4954C14.6363 17.4869 13.9178 18.2965 13.0172 18.2965ZM18.9945 18.2965C18.103 18.2965 17.3744 17.4869 17.3744 16.4954C17.3744 15.5039 18.0848 14.6943 18.9945 14.6943C19.895 14.6943 20.6318 15.5039 20.6136 16.4954C20.6136 17.4869 19.9041 18.2965 18.9945 18.2965Z"
            fill="#4C6FFF"
          />
        </svg>
      </a>
    </div>
  </footer>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["twitter", "discord"]),
  },
};
</script>
<style scoped>
footer {
  padding: 24px 0;
  border-top: 1px solid #e5e5e5;
}
footer > div {
  display: flex;
}
footer a {
  margin-right: 12px;
  line-height: 0;
}
footer a:last-child {
  margin-right: 0;
}
</style>
