<template>
  <div id="app">
    <NavPart />
    <router-view />
    <Foot />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import NavPart from "@/components/Nav.vue";
import Foot from "@/components/Foot.vue";
export default {
  components: {
    NavPart,
    Foot,
  },
  methods: {
    ...mapActions(["setInfo"]),
    async getInfo() {
      try {
        this.$loading(1);
        let res = await this.axios.get(`mathverse/world/${this.worldPath}/index`);

        if (res?.success) {
          let info = res?.data;
          this.setInfo(info);
          document.title = info?.name || "MathVerse";
          let root = document.querySelector(":root");
          // 默认主题可以修改颜色和字体
          let font = info?.theme_info?.theme_font || "Gotham";
          root.style.setProperty("--fontRegular", `${font}-Regular`);
          root.style.setProperty("--fontBold", `${font}-Bold`);
          // 更改主题色
          if (
            info?.theme_info?.theme_color &&
            info?.theme_info?.theme_color?.toLowerCase() != "#fff" &&
            info?.theme_info?.theme_color?.toLowerCase() != "#ffffff"
          ) {
            root.style.setProperty(
              "--mainColor",
              info?.theme_info?.theme_color
            );
          }
        } else {
          this.$router.push("/404");
        }
        this.$loading(0);
      } catch (err) {
        this.$router.push("/404");
        this.$loading(0);
      }
    },
  },
  watch: {
    worldPath(to) {
      if (to) {
        this.getInfo();
      } else {
        this.setInfo(null);
      }
    },
  },
};
</script>
